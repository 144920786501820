import React, {useEffect, useState} from 'react';
import { ChevronIcon } from '../appearance/icons/chevron_icon';
import { ELSI_API_URL, ELSI_CABINET_URL, errorCallback, makeUrl } from '../const/const';
import { toast } from 'react-toastify';
import { LOGIN_REQUEST, CHANGE_PASSWORD_REQUEST, MAKE_USER_REQUEST, postFetchRequest } from '../store/requests';


const handleCheckUserData = (email, phone, name, setUserDataError) => {
    if (email && phone && name) {
        if (email) {
            const result = email.match(/^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/i);
            if (!result) {
                setUserDataError({ email: 'Некорректный email' });
                return false;
            }
        }
        if (phone) {
            const result = phone.match(/^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/);
            if (!result) {
                setUserDataError({ phone: 'Некорректный телефон' });
                return false;
            }
        }
        if (name) {
            const result = name.match(/[\D]{3,}$/);
            if (!result) {
                setUserDataError({ name: 'Имя должно содержать не меньше 3 символов и начинаться с буквы' });
                return false;
            }
        }
        setUserDataError({});
        return true;
    } else {
        setUserDataError({ required: 'Заполните все поля' });
        return false;
    }
};

export default function LoginPage() {
    const [passwordIsReseted, setPasswordIsReseted] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userName, setUserName] = useState('');
    const [userDataError, setUserDataError] = useState({});

    useEffect(() => {
        if(localStorage.getItem('savedNewPassword')) {
            toast('Пароль успешно создан', {autoClose:5000, 
                type: 'success', 
                closeOnClick: true,
                position:'top-left',
                theme:"light"});
            localStorage.removeItem('savedNewPassword');
        }
    }, []);

    const succesPasswordResetCallback = (response) => {
        if(response.status===200) {
            setPasswordIsReseted(false);
            setEmailValue('');
        }
    };

    const succesLoginCallback = (response) => {
        if(response.token) {
            const { token } = response;
            localStorage.setItem('token', token);
            window.location.href = ELSI_CABINET_URL;
        }
    };

    const succesCreateUserCallback = (response) => {
        if(response.status === 201) {
            setUserName('');
            setUserEmail('');
            setUserPhone('');
        } else {
            setUserDataError({createUser: "Пользователь не был создан"});
        }
    };

    const handleResetPassword = () => {
        if(emailValue) {
            const data = {email: emailValue};
            postFetchRequest(makeUrl([CHANGE_PASSWORD_REQUEST]), data, succesPasswordResetCallback, errorCallback, true);
        }
    };
    const handleLogin = () => {
        if(login && password) {
            const data = {login, password};
            postFetchRequest(makeUrl([LOGIN_REQUEST]), data, succesLoginCallback, errorCallback);
        }
    };
    const handleCreateUser = () => {
        if(handleCheckUserData(userEmail, userPhone, userName, setUserDataError)) {
            const data = {name: userName.trim(), phone: userPhone, email: userEmail};
            postFetchRequest(makeUrl([MAKE_USER_REQUEST]), data, succesCreateUserCallback, errorCallback, true);
        }
    };

    const handleKeyDown = (e) => {
        if(e.keyCode === 13) {
            if(passwordIsReseted) {
                handleResetPassword();
            } else if(login&&password) {
                handleLogin();
            } else {
                handleCreateUser();
            }
        }
    };

    return (
        <main className="login-main container" onKeyDown={(e) => handleKeyDown(e)}>
            <h1 className="login-main-title">Вход / Регистрация</h1>
            <div className="login-card-left">
                <h2 className="login-card__title">{passwordIsReseted?'Сбросить пароль':'Зарегистрирован'}</h2>
                {passwordIsReseted ?
                    <label className="login-card__label login-card-left__label_first">
                        Введите e-mail:
                        <input value={emailValue} role="presentation" autoComplete="off"
                            onChange={(e)=> setEmailValue(e.target.value.trim())}
                            className="login-card__input login-card-left__input" />
                    </label>:''}
                {!passwordIsReseted ?
                    <label className="login-card__label login-card-left__label_first">
                        E-mail
                        <input value={login} autoComplete="off"
                            onChange={(e)=> setLogin(e.target.value.trim())} 
                            className="login-card__input login-card-left__input" />
                    </label>:''}
                {!passwordIsReseted ?
                    <label className="login-card__label login-card-left__label">
                        Пароль
                        <input value={password} autoComplete="new-password"
                            onChange={(e)=> setPassword(e.target.value.trim())}
                            type="password" className="login-card__input login-card-left__input" />
                    </label>:''}
                <a className="login-card-left__link" onClick={()=>setPasswordIsReseted(!passwordIsReseted)}>{passwordIsReseted ? "Назад" : "Забыли пароль?"}</a>
                <button className={`button login-main__button login-main__button-left ${passwordIsReseted?'login-main__button_reset':''}`}
                    onClick={passwordIsReseted?handleResetPassword:handleLogin}>
                    <span className="button__text">{passwordIsReseted?'Сбросить пароль':'Войти'} </span>
                    <ChevronIcon nameOfClass="button__icon" />
                </button>
            </div>
            <div className="login-card-right">
                <h2 className="login-card__title">Заказываю впервые</h2>
                <p className="login-card-right__content">
                    После регистрации будет создан личный кабинет, в котором вы сможете оформлять персональные заказы или заказы от юридического лица
                </p>
                <label className="login-card__label login-card__label-email">
                    E-mail**
                    <input value={userEmail} role="presentation" autoComplete="off"
                        onChange={(e)=> setUserEmail(e.target.value.trim())}
                        name='email' className="login-card__input login-card-right__input"/>
                    {userDataError.email&& <span className='alert alert__login-input'>{userDataError.email}</span>}
                </label>
                <label className="login-card__label login-card__label-phone">
                    Номер телефона*
                    <input value={userPhone} autoComplete="off"
                        onChange={(e)=> setUserPhone(e.target.value.trim())}
                        name="phone" className="login-card__input login-card-right__input" />
                    {userDataError.phone&& <span className='alert alert__login-input'>{userDataError.phone}</span>}
                </label>
                <label className="login-card__label login-card__label-name">
                    Как к вам обращаться?
                    <input value={userName} autoComplete="off"
                        onChange={(e)=> setUserName(e.target.value)}
                        name="name" className="login-card__input login-card-right__input" />
                    {userDataError.name&& <span className='alert alert__login-input'>{userDataError.name}</span>}
                </label>
                <p className="login-card-right__text">*Мы позвоним вам на указанный номер телефона</p>
                <p className="login-card-right__text">**На указанный e-mail вам будет отправлена ссылка для авторизации</p>
                <button className="button login-card-right__button"
                    onClick={handleCreateUser}>
                    <span className="button__text">Регистрация </span>
                    <ChevronIcon nameOfClass="button__icon" />
                </button>
                {userDataError.required&& <span className='alert alert__login'>{userDataError.required}</span>}
                {userDataError.createUser&& <span className='alert alert__login'>{userDataError.createUser}</span>}
            </div>
        </main>
    );
};