import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginPage from "../components/LoginPage";

export default function Delivery() {
    return (
        <>
            <Header />
            <LoginPage />
        </>
    );
};